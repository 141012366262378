import React from "react"

import { graphql } from "gatsby"
import { useStaticQuery } from "gatsby"

export const useSiteQuery = () => {
  const { contentfulGlobalSite } = useStaticQuery(graphql`
    query {
      contentfulGlobalSite(id: { eq: "e5294626-8eb0-5c27-83e0-0cad4f1c3bf1" }) {
        footerLinks {
          ...componentLink
        }
        privacyLink {
          ...componentLink
        }
        termsLink {
          ...componentLink
        }
        footerLogo {
          gatsbyImageData(quality: 100)
          title
        }
        headerButton {
          title
          slug
        }
        headerItems {
          __typename
          ...componentLink
          ...componentMenu
        }
        headerLogo {
          gatsbyImageData(quality: 100)
          title
        }
        darkThemeHeaderLogo {
          gatsbyImageData(quality: 100)
          title
        }
        darkThemeFooterLogo {
          gatsbyImageData(quality: 100)
          title
        }
        performancePageSections {
          __typename
          ...sectionsBreadcrumbs
          ...sectionHero
          ...sectionContent
        }
        performanceFcp {
          raw
        }
        performanceFmp {
          raw
        }
        performanceLcp {
          raw
        }
        performanceSi {
          raw
        }
        performanceTbt {
          raw
        }
        performanceTti {
          raw
        }
        performanceFcpUrl
        performanceFmpUrl
        performanceLcpUrl
        performanceSiUrl
        performanceTbtUrl
        performanceTtiUrl
        profilePageSections {
          __typename
          ...sectionsBreadcrumbs
          ...sectionHero
        }
        ticketsPageSection {
          __typename
          ...sectionsBreadcrumbs
          ...sectionContent
        }
        zendeskBrandField
        zendeskMarketField
        zendeskTypeField
        profilePageInstructions {
          raw
        }
        profilePageSections {
          __typename
          ...sectionsBreadcrumbs
        }
        profileRoleType
        lighthouseBrands {
          brandName
          marketName
          maketUrl
        }
        kotaklifeBrands {
          brandName
          marketName
          marketUrl
        }
        hamleysBrands {
          brandName
          marketName
          marketUrl
        }
        silktideBrands {
          brandName
          marketName
          maketUrl
        }
        zendeskFields {
          brandTitle
          marketField
        }
        signUpPageVideo {
          file {
            url
          }
          publicUrl
        }
        silktidePageSection {
          __typename
          ...sectionsBreadcrumbs
          ...sectionHero
          ...sectionContent
        }
        marketingModule {
          raw
        }
        accessibility {
          raw
        }
        contentModule {
          raw
        }
        userExperience {
          raw
        }
        marketingModuleUrl
        accessibilityUrl
        contentModuleUrl
        userExperienceUrl
        announcementsArray {
          name
          announcementText {
            raw
            references {
              ...richTextReference
            }
          }
        }
      }
    }
  `)
  return contentfulGlobalSite
}
